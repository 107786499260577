import { Modal } from "antd";

export const ConfirmationModal = ({
  visible,
  onConfirm,
  onCancel,
  title,
  content,
}) => {
  return (
    <Modal
      title={title || "Confirmação"}
      visible={visible}
      onOk={onConfirm}
      onCancel={onCancel}
      okText="Confirmar"
      cancelText="Cancelar"
    >
      <p>{content || "Tem certeza que deseja realizar esta ação?"}</p>
    </Modal>
  );
};
