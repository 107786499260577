import { Button } from "antd"
import { TableComponent as Table } from "../../components"
import Link from "antd/lib/typography/Link"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { ConfirmationModal } from "../../components/Modal/ConfirmModal"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { deletePersonal } from "../../redux/slices/personalsSlice"
import { EditProfessionalModal } from "../../components/Modal/EditProfessionalModal"


const TableProfessionals = ({ isLoading, data }) => {
    const [localData, setLocalData] = useState(data);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const dispatch = useDispatch()
    const showModal = (row) => {
        setIsModalVisible(true);
        setSelectedRow(row)
    };


    const handleConfirm = async () => {
        if (!selectedRow) {
            console.error('Nenhuma linha selecionada.');
            return;
        }

        try {
            const resultAction = await dispatch(deletePersonal(Number(selectedRow.id)));

            if (deletePersonal.fulfilled.match(resultAction)) {

                // Remover o item localmente
                setLocalData(prevData => prevData.filter(item => item.id !== selectedRow.id));
                // Limpar modal e estado
                setIsModalVisible(false);
                setSelectedRow(null);
            } else {
                console.error('Erro ao deletar o usuário:', resultAction.payload || resultAction.error.message);
            }
        } catch (error) {
            console.error('Erro inesperado:', error);
        }
    };
    const handleCancel = () => {

        setIsModalVisible(false);
        setSelectedRow(null)
    };
    const handleEditCancel = () => {
        setIsEditModalVisible(false);
        setSelectedRow(null);
    };
    const showEditModal = (row) => {
        setIsEditModalVisible(true);
        setSelectedRow(row);
    };


    useEffect(() => {
        setLocalData(data);
    }, [data]);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            align: 'center'
        }, {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center'
        }, {
            title: 'Nome',
            dataIndex: 'firstName',
            key: 'name',
            align: 'center',
            render: (value, row) => {
                return (<Link href={`/profissionais/${row.id}`}>{value} {row.lastName}</Link>)
            }
        }, {
            title: 'Data de cadastro',
            dataIndex: 'creationDate',
            key: 'creationDate',
            align: 'center',
            render: (value) => {
                const date = new Date(value)
                let month = date.getMonth() + 1
                month = month <= 9 ? '0' + month : month
                return date.getDate() + '/' + month + '/' + date.getFullYear()
            }
        }, {
            title: 'Ações',
            dataIndex: 'actions',
            key: 'actions',
            align: 'center',
            render: (value, row) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            gap: '0.5rem', // Adiciona um espaço de 0.5rem entre os botões
                            justifyContent: 'center', // Centraliza os botões
                            alignItems: 'center' // Alinha verticalmente os botões
                        }}
                    >
                        <Button type="primary"
                            onClick={() => {
                                showEditModal(row);
                            }}

                        >Editar</Button>
                        <Button className="ml-20" type="danger"
                            onClick={() => {
                                showModal(row);
                            }}
                        >
                            Deletar
                        </Button>
                    </div>
                )
            }
        },
    ]


    return (
        <div>

            <Table columns={columns} data={localData} isLoading={isLoading} />
            <ConfirmationModal
                visible={isModalVisible}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                title="Deletar item"
                content="Tem certeza que deseja deletar este item?"
            />

            {selectedRow && (
                <EditProfessionalModal
                    visible={isEditModalVisible}
                    onConfirm={() => setIsEditModalVisible(false)} // Lógica de confirmação pode ser ajustada
                    onCancel={handleEditCancel}
                    personal={selectedRow} // Passando os dados do profissional selecionado
                />
            )}
        </div>
    )
}

export default TableProfessionals