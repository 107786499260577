import { TableComponent as Table } from "../../components"
import { Button } from "antd"

const TableGyms = ({
    data,
    isLoading,
    handleOpenModal,
    onDelete,
    handleEditModal }) => {

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            align: 'center'
        }, {
            title: 'Nome',
            dataIndex: 'name',
            key: 'nome',
            align: 'center'
        }, {
            title: 'Endereço',
            dataIndex: 'street',
            key: 'endereco',
            align: 'center'
        }, {
            title: '',
            dataIndex: 'id',
            key: 'action',
            render: (record, item, index) =>
                <div className="d-flex">
                    <Button type="primary"
                        onClick={() => handleEditModal(item)}>Editar</Button>
                    <Button className="ml-20" type="danger"
                        onClick={() => onDelete({ record, ...item })}>Deletar</Button>
                </div>
        }
    ]

    return (
        <Table isLoading={isLoading} columns={columns} data={data} />
    )

}

export default TableGyms