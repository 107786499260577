import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { request, setInfoLocalStorage } from "../api/config"

const initialState = {
    isLoading: false,
    success: false,
    message: null,
    personals: [],
    personal: [],
    isSubmit: false,
    authId: ''
}

export const getPersonals = createAsyncThunk(
    'personals/',
    async (values, thunkAPI) => {
        try {
            const res = await request('/personals')
            if (res.code === 200) {
                return res.value
            } else {
                return thunkAPI.rejectWithValue('Houve um erro ao retornar os personais.')
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao retornar os personais.')
        }
    }
)

export const getPersonal = createAsyncThunk(
    'personals/id',
    async (values, thunkAPI) => {
        try {
            const res = await request(`/personals/${values}`)
            if (res.code === 200) {
                return res.value
            } else {
                return thunkAPI.rejectWithValue('Houve um erro ao retornar os personais.')
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao retornar os personais.')
        }
    }
)

export const createPersonal = createAsyncThunk(
    'personals/create',
    async (values, thunkAPI) => {
        try {

            const res = await request(values?.id ? `/personals/${values?.id}` : `/personals/${values.authId}`, values?.id ? 'PUT' : 'POST', values)
            if (res.code === 200) {
                return res.value
            } else {
                return thunkAPI.rejectWithValue('Houve um erro ao criar o profissional.')
            }
        } catch (e) {
            console.log(e)
            return thunkAPI.rejectWithValue('Houve um erro ao criar o profissional.')
        }
    }
)

export const createPersonalAuth0 = createAsyncThunk(
    'personals/create-auth0',
    async (values, thunkAPI) => {
        const data = {
            "client_id": "UV1IjvZwV9ER2T5rlN5CyAdd3FeR2ycS",
            "client_secret": "jLrWC3NUjUqnOoVvMjFfLCx3EHzeTzIZEcm9bDNF3N3hy_3iIwir7_34AtQ_gPR2",
            "audience": "https://yellow-tooth-5932.us.auth0.com/api/v2/",
            "grant_type": "client_credentials"
        }
        try {

            const res = await request('https://yellow-tooth-5932.us.auth0.com/oauth/token', 'POST', data)
            const authInfo = { authToken: res.access_token }
            setInfoLocalStorage(authInfo)

            try {

                const res = await request('https://yellow-tooth-5932.us.auth0.com/api/v2/users', 'POST', values)
                return res
            } catch (e) {
                console.log(e)
                return thunkAPI.rejectWithValue('Houve um erro ao criar o profissional.')
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao gerar o token.')
        }
    }
)

export const postAuthRole = createAsyncThunk(
    'personals/user-role',
    async (values, thunkAPI) => {
        try {
            const res = await request('https://yellow-tooth-5932.us.auth0.com/api/v2/roles/rol_a411Lq9wdZQO2IDS/users', 'POST', { users: values })
            return res
        } catch (e) {
            console.log(e)
            return thunkAPI.rejectWithValue('Houve um erro ao atualizar a role do usuário')
        }
    }
)

export const deletePersonal = createAsyncThunk(
    'personals/delete',
    async (personalID, thunkAPI) => {
        try {
            const res = await request(`/personals/${personalID}`, 'DELETE');
            if (res.code === 200) {
                return personalID; // Retorna o ID do personal deletado para ser removido da lista
            } else {
                return thunkAPI.rejectWithValue('Houve um erro ao deletar o personal no backend.');
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao deletar o personal e/ou o usuário no Auth0.');
        }
    }
);

export const getPersonalAuth0 = createAsyncThunk(
    'personals/get-auth0',
    async (values, thunkAPI) => {
        const data = {
            "client_id": "UV1IjvZwV9ER2T5rlN5CyAdd3FeR2ycS",
            "client_secret": "jLrWC3NUjUqnOoVvMjFfLCx3EHzeTzIZEcm9bDNF3N3hy_3iIwir7_34AtQ_gPR2",
            "audience": "https://yellow-tooth-5932.us.auth0.com/api/v2/",
            "grant_type": "client_credentials"
        }
        try {
            const res = await request('https://yellow-tooth-5932.us.auth0.com/oauth/token', 'POST', data)
            const authInfo = { authToken: res.access_token }
            setInfoLocalStorage(authInfo)

            try {
                const res = await request(`https://yellow-tooth-5932.us.auth0.com/api/v2/users?q=email:"${values.email}"&search_engine=v3`)
                return res[0].user_id
            } catch (e) {
                return thunkAPI.rejectWithValue('Houve um erro ao retornar o profissional')
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao gerar o token.')
        }
    }
)

export const uploadPersonalImage = createAsyncThunk(
    'personals/uploadImage', // Renomeado para melhor refletir a ação
    async (values, thunkAPI) => {
        console.log("values id", values)
        try {

            const url = `/persons/${values.id}/profile/photo`;


            const res = await request(url, 'PUT', { photo: values.photo }); // Use 'POST' ou 'PUT' conforme necessário

            if (res.code === 200) {
                return res.value;
            } else {
                return thunkAPI.rejectWithValue('Houve um erro ao enviar a imagem.');
            }
        } catch (e) {
            console.log(e);
            return thunkAPI.rejectWithValue('Houve um erro ao enviar a imagem.');
        }
    }
);
const personalsSlice = createSlice({
    name: 'personals',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(getPersonals.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getPersonals.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.success = true
                state.personals = payload
            })
            .addCase(getPersonals.rejected, (state, { payload }) => {
                state.isLoading = false
                state.success = false
                state.message = payload
            })
            .addCase(getPersonal.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getPersonal.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.success = true
                state.personal = payload
            })
            .addCase(getPersonal.rejected, (state, { payload }) => {
                state.isLoading = false
                state.success = false
                state.message = payload
            })
            .addCase(createPersonal.pending, (state) => {
                state.isSubmit = true
            })
            .addCase(createPersonal.fulfilled, (state, { payload }) => {
                state.isSubmit = false
                state.success = true
            })
            .addCase(createPersonal.rejected, (state, { payload }) => {
                state.isSubmit = false
                state.success = false
            })
            .addCase(createPersonalAuth0.pending, (state) => {
                state.isSubmit = true
            })
            .addCase(createPersonalAuth0.fulfilled, (state, { payload }) => {
                state.isSubmit = false
                state.success = true
            })
            .addCase(createPersonalAuth0.rejected, (state, { payload }) => {
                state.isSubmit = false
                state.success = false
            })
            .addCase(getPersonalAuth0.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getPersonalAuth0.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.success = true
                state.authId = payload
            })
            .addCase(getPersonalAuth0.rejected, (state, { payload }) => {
                state.isLoading = false
                state.success = false
            })
            .addCase(postAuthRole.pending, (state) => {
                state.isSubmit = true
            })
            .addCase(postAuthRole.fulfilled, (state, { payload }) => {
                state.isSubmit = false
                state.success = true
            })
            .addCase(postAuthRole.rejected, (state, { payload }) => {
                state.isSubmit = false
                state.success = false
            })
            .addCase(deletePersonal.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deletePersonal.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.success = true;
                state.personals = state.personals.filter(personal => personal.id !== payload);
            })
            .addCase(deletePersonal.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.success = false;
                state.message = payload;
            })
            .addCase(uploadPersonalImage.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(uploadPersonalImage.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.success = true;

            })
            .addCase(uploadPersonalImage.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.success = false;
                state.message = payload;
            });;
    }
})

const { reducer } = personalsSlice

export default reducer

