import React, { useState, useRef } from "react";
import { Tooltip, Spin, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import user from "../../assets/img/user.png"; // Caminho para a imagem padrão
import { uploadObject } from "../../utils/bucket";
import { sanitizeFileName } from "../../utils/sanitizeFilename";

const SPACES_URL =
  "https://dev-personal-space.nyc3.digitaloceanspaces.com/personal-image/";

export const EditableImage = ({ initialImage, onChange, form }) => {
  const [imageUrl, setImageUrl] = useState(initialImage || user);
  const [isHovering, setIsHovering] = useState(false);
  const [sendingImage, setSendingImage] = useState(false);
  const fileInputRef = useRef(); // Referência para o input de arquivo

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && !sendingImage) {
      setSendingImage(true);
      handleUpload({
        file,
        onError: () => {
          setSendingImage(false);
          message.error("Falha no envio da imagem.");
        },
        onSuccess: () => {
          setSendingImage(false);
          message.success("Imagem enviada com sucesso.");
        },
      });
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const dataURL = fileReader.result;
        setImageUrl(dataURL);
      };
      fileReader.readAsDataURL(file);
    }
  };

  const handleUpload = async ({ file, onSuccess, onError }) => {
    if (!file) {
      alert("Please, insira um arquivo");
      return;
    }
    const current = new Date().getTime();
    const name = current + sanitizeFileName(file.name);

    const uploadResponse = await uploadObject(name, file, "personal");

    onChange(SPACES_URL + name);
    if (uploadResponse) {
      onSuccess();
      // form.setFieldValue("photo", SPACES_URL + name);
    } else {
      onError();
    }
  };

  // Função para abrir o input de arquivo
  const triggerUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div
      className="editable-image-container"
      style={{ position: "relative", display: "inline-block" }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <img src={imageUrl} alt="Imagem do usuário" className="img-personal" />

      {isHovering && (
        <Tooltip title="Alterar imagem">
          <div
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={triggerUpload}
          >
            <EditOutlined style={{ color: "#fff", fontSize: "18px" }} />
          </div>
        </Tooltip>
      )}

      {/* Input de arquivo escondido */}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept="image/*"
        onChange={handleImageChange}
      />

      {sendingImage && (
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            borderRadius: "50%",
            transition: "background-color 0.3s ease",
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};
