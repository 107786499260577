import React from "react";
import { Modal } from "antd";
import { PersonalForm } from "../Forms/PersonalForm";

export const EditProfessionalModal = ({
  visible,
  onConfirm,
  onCancel,
  personal,
  title,
  id,
}) => {
  return (
    <Modal
      title={title || ""}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <PersonalForm personal={personal} onClose={onCancel} id={id} />
    </Modal>
  );
};
