import { useState, useEffect } from "react";
import { Card, Checkbox, Button, message as alert, Form } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getPersonals,
  uploadPersonalImage,
} from "../../redux/slices/personalsSlice";

import { EditableImage } from "../Inputs/EditableImage";

export const PersonalForm = ({ personal, onClose, id }) => {
  const dispatch = useDispatch();

  const { languages } = useSelector((state) => state.languages);
  const { gyms } = useSelector((state) => state.gyms);
  const { specialties } = useSelector((state) => state.specialties);

  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedSpecialties, setSelectedSpecialties] = useState([]);
  const [selectedGyms, setSelectedGyms] = useState([]);
  const genders = { FEMALE: "Feminino", MALE: "Masculino" };

  const [image, setImage] = useState();

  useEffect(() => {
    if (personal) {
      setSelectedLanguages(personal.languages || []);
      setSelectedSpecialties(personal.specialties || []);
      setSelectedGyms(personal.locals || []);
    }
  }, [personal]);

  const handleCheck = (item, type) => {
    const copy =
      type === "LANGUAGE"
        ? [...selectedLanguages]
        : type === "GYM"
        ? [...selectedGyms]
        : [...selectedSpecialties];

    const setSelected =
      type === "LANGUAGE"
        ? setSelectedLanguages
        : type === "GYM"
        ? setSelectedGyms
        : setSelectedSpecialties;
    const index = copy.findIndex(
      (i) => JSON.stringify(i) === JSON.stringify(item)
    );

    if (index === -1) copy.push(item);
    else copy.splice(index, 1);

    setSelected([...copy]);
  };

  const returnGyms = () => {
    const lenght = personal.locals.length;
    if (lenght === 1) {
      return personal.locals[0]?.name;
    }
    if (lenght >= 2) {
      const diff = lenght - 2;
      if (diff > 0) {
        return (
          personal.locals[0]?.name +
          ", " +
          personal.locals[1]?.name +
          ", +" +
          diff
        );
      } else {
        return personal.locals[0]?.name + ", " + personal.locals[1]?.name;
      }
    }
  };
  const handleSubmit = async () => {
    try {
      await dispatch(uploadPersonalImage({ id: personal.id, photo: image }));

      await dispatch(getPersonals());

      onClose();
      alert.success("Personal atualizado com sucesso!");
    } catch (error) {
      alert.error("Erro ao atualizar o personal.");
    }
  };
  const returnLanguages = () => {
    const lenght = personal.languages.length;
    if (lenght === 1) {
      return personal.languages[0]?.name;
    }
    if (lenght >= 2) {
      const diff = lenght - 2;
      if (diff > 0) {
        return (
          personal.languages[0]?.name +
          ", " +
          personal.languages[1]?.name +
          ", +" +
          diff
        );
      } else {
        return personal.languages[0]?.name + ", " + personal.languages[1]?.name;
      }
    }
  };

  const returnSpecialties = () => {
    const lenght = personal.specialties.length;
    if (lenght === 1) {
      return personal.specialties[0]?.name;
    }
    if (lenght >= 2) {
      const diff = lenght - 2;
      if (diff > 0) {
        return (
          personal.specialties[0]?.name +
          ", " +
          personal.specialties[1]?.name +
          ", +" +
          diff
        );
      } else {
        return (
          personal.specialties[0]?.name + ", " + personal.specialties[1]?.name
        );
      }
    }
  };

  const handleImageChange = async (event) => {
    setImage(event);
  };

  return (
    <div>
      {" "}
      <div className="wrapper-professional">
        <div className="d-flex">
          <EditableImage
            initialImage={personal.photo}
            isEditMode={false}
            onChange={handleImageChange}
          />

          <div className="id-wrapper">
            <span className="tx-gray">ID: {id}</span>
            <h3>
              {personal.firstName} {personal.lastName}
            </h3>
          </div>
        </div>
        <div className="d-flex mt-50 w-100 justify-between">
          <span>
            <b>CREF:</b> {personal.cref}
          </span>
          <span>
            <b>CPF:</b> {personal.cpf}
          </span>
        </div>
        <div className="d-flex w-100 mt-50">
          <h4 className="w-40 tx-bold">E-mail</h4>
          <span>{personal.email}</span>
        </div>
        <div className="d-flex w-100">
          <h4 className="w-40 tx-bold">Gênero</h4>
          <span>{genders[personal.gender]}</span>
        </div>
        <div className="d-flex w-100">
          <h4 className="w-40 tx-bold">Nascimento</h4>
          <span>{moment(personal.birthDate).format("DD/MM/YYYY")}</span>
        </div>
        <hr />
        <div className="d-flex w-100">
          <h4 className="w-40 tx-bold">Idiomas</h4>
          <span>
            {personal.languages && personal.languages.length > 0
              ? returnLanguages()
              : "Nenhum idioma cadastrado"}
          </span>
          {/* <EditOutlined onClick={() => setModalLanguage(true)} /> */}
        </div>
        <hr />
        <div className="d-flex w-100">
          <h4 className="w-40 tx-bold">Academias</h4>
          <span>
            {personal.locals && personal.locals.length > 0
              ? returnGyms()
              : "Nenhuma academia cadastrada"}
          </span>
          {/* <EditOutlined onClick={() => setModalGyms(true)} /> */}
        </div>
        <hr />
        <div className="d-flex w-100">
          <h4 className="w-40 tx-bold">Especialidades</h4>
          <span>
            {personal.specialties && personal.specialties.length > 0
              ? returnSpecialties()
              : "Nenhuma especialidade cadastrada"}
          </span>
          {/* <EditOutlined onClick={() => setModalSpecialty(true)} /> */}
        </div>
      </div>
      <div
        className="d-flex justify-content-center w-100"
        style={{ textAlign: "center" }}
      >
        <div style={{ width: "100%", maxWidth: "400px" }}>
          <Button
            type="default"
            onClick={onClose}
            style={{ marginRight: "8px" }}
          >
            Cancelar
          </Button>
          <Button type="primary" onClick={handleSubmit}>
            Salvar
          </Button>
        </div>
      </div>
    </div>
  );
};
